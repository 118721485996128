import { lazy, Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Loading from "./components/shared/Loading/Loading";
import ScrollToTop from "./ScrollToTop";
import "./App.scss";
import Aos from "aos";
import "aos/dist/aos.css";
// import { IntlProvider } from 'react-intl';

const Home = lazy(() => import("./pages/Home/Home"));
const SignUp = lazy(() => import("./pages/SignUp/SignUp"));
const LogIn = lazy(() => import("./pages/LogIn/LogIn"));
const Terms = lazy(() => import("./pages/Terms/Terms"));
const Privacy = lazy(() => import("./pages/Privacy/Privacy"));
const AboutUs = lazy(() => import("./pages/AboutUs/AboutUs"));
const ContactUs = lazy(() => import("./pages/ContactUs/ContactUs"));
const Artists = lazy(() => import("./pages/Artists/Artists"));
// const MixedMedia = lazy(() => import("./pages/MixedMedia/MixedMedia"));
const Artist = lazy(() => import("./pages/Artist/Artist"));
const Shop = lazy(() => import("./pages/Shop/Shop"));
const Movement = lazy(() => import("./pages/Movement/Movement"));
const Cubism = lazy(() => import("./pages/Cubism/Cubism"));
const ModernArt = lazy(() => import("./pages/ModernArt/ModernArt"));
const Surrealism = lazy(() => import("./pages/Surrealism/Surrealism"));
const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const Picture = lazy(() => import("./pages/Picture/Picture"));
const AdminPanel = lazy(() => import("./pages/AdminPanel/AdminPanel"));
const ShowCase = lazy(() => import("./pages/ShowCase/ShowCase"));
const CheckoutSuccess = lazy(() => import('./pages/Checkout/CheckoutSuccess'))
const NotFound = lazy(() => import('./pages/NotFound/NotFound'))
const Nationality = lazy(() => import('./pages/Nationality/Nationality'))
const NationArtists = lazy(() => import('./pages/NationArtists/NationArtists'))
const ReturnPolicy = lazy(() => import('./pages/ReturnPolicy/ReturnPolicy'))

function App() {
  useEffect(() => {
    Aos.init({
      duration: 1800,
      once: true,
    });
  }, []);

  return (
    <Router>
      <Suspense fallback={<Loading />}>
        {/* <IntlProvider> */}
        <ScrollToTop />

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/SignUp" element={<SignUp />} />
          <Route exact path="/SignIn" element={<LogIn />} />
          <Route exact path="/Terms&Conditions" element={<Terms />} />
          <Route exact path="/PrivacyPolicy" element={<Privacy />} />
          <Route exact path="/AboutUs" element={<AboutUs />} />
          <Route exact path="/ContactUs" element={<ContactUs />} />
          <Route exact path="/Artists" element={<Artists />} />
          {/* <Route exact path="/MixedMedia" element={<MixedMedia />} /> */}
          <Route
            exact
            path="/Artist/:id/:firstName/:lastName"
            element={<Artist />}
          />
          <Route exact path="/Shop" element={<Shop />} />
          <Route exact path="/Movement" element={<Movement />} />
          <Route exact path="/Cubism" element={<Cubism />} />
          <Route exact path="/ModernArt" element={<ModernArt />} />
          <Route exact path="/Surrealism" element={<Surrealism />} />
          <Route exact path="/Profile" element={<Dashboard />} />
          <Route
            exact
            path="/Picture/:userId/:picId/:picName"
            element={<Picture />}
          />
          <Route exact path="/AdminPanel" element={<AdminPanel />} />
          <Route exact path="/ShowCase" element={<ShowCase />} />
          <Route exact path="/checkout-success" element={<CheckoutSuccess />} />
          <Route exact path="/Nationality" element={<Nationality />} />
          <Route exact path="/ReturnPolicy" element={<ReturnPolicy />} />
          <Route exact path="/NationArtists/:label" element={<NationArtists />} />

          <Route exact path="*" element={<NotFound />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        {/* </IntlProvider> */}
      </Suspense>
    </Router>
  );
}

export default App;
